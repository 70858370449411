import { useMediaQuery } from "@mui/material";
import { CSSProperties } from "react";
import { isPhone, theme } from "../../../theme";

interface UseStylesProps {
  showCredentials?: boolean;
}

export function useStyles({ showCredentials }: UseStylesProps) {
  const matchPhone = useMediaQuery(isPhone);
  const styles = {
    form: {
      height: "100%",
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    } as CSSProperties,
    fields: {
      width: "85%",
      margin: "15px 0px 15px 0px",
      background: "white",
    },
    error: {
      color: "red",
      marginTop: 10,
    },
    container: {
      textAlign: "center",
      minHeight: "100vh",
      display: "flex",
    } as CSSProperties,
    leftContainer: {
      display: showCredentials && matchPhone ? "none" : "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: !showCredentials && matchPhone ? "100%" : "50%",
      backgroundColor:
        !showCredentials && matchPhone
          ? theme.colors.primary
          : theme.colors.white,
    } as CSSProperties,
    rightContainer: {
      display: !showCredentials && matchPhone ? "none" : "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: showCredentials && matchPhone ? "100%" : "50%",
      backgroundColor: theme.colors.primary,
    } as CSSProperties,
    registerForm: {
      height: 390,
      width: matchPhone ? "100%" : "55%",
      backgroundColor: theme.colors.secondary,
      margin: "auto",
      boxShadow: theme.shadow.form,
    },
    formTitle: {
      color: "white",
    },
    title: {
      fontWeight: 700,
      fontSize: 42,
      color: "white",
    },
    title2: {
      fontWeight: 700,
      fontSize: 42,
      color: theme.colors.secondary,
    },
    button: {
      margin: "0px 4px 0px 4px",
    } as CSSProperties,
  };

  return {
    styles,
    matchPhone,
  };
}

import React from "react";
import { Link } from "react-router-dom";
import { TransactionPost } from "../../../../../types/transactions";
import { OfferRow } from "../../../../Offers/types/table-models";
import { styles } from "./styles";

interface ModalHeaderProps {
  post: TransactionPost | OfferRow;
  model: string;
  status: string;
}

function ModalHeader({ post, model, status }: ModalHeaderProps) {
  return (
    <>
      <h3 style={styles.heading}>
        {status} {model}...
      </h3>
      <h2 style={styles.title}>
        <Link to={`/post/${post._id}`}>{post.title}</Link>
      </h2>
    </>
  );
}

export default React.memo(ModalHeader);

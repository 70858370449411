import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { usePostsContext } from "../../../../../context/posts";
import { ModalTypes } from "../../../../../types/common";
import { OfferPost, OfferStatus } from "../../../../../types/offers";
import useToggleModal from "../../../../components/hooks/useToggleModal";
import SmallButton from "../../../../components/SmallButton";
import { useConversionRate } from "../../../../Payments/hooks/useConversionRate";
import { styles } from "./styles";

export default function AwaitingPayment() {
  const [pendingPayments, setPendingPayments] = useState<OfferPost[]>([]);
  const { state, refetchOffersSent } = usePostsContext();
  const { btcToUsd } = useConversionRate();
  const { toggle } = useToggleModal();
  useEffect(() => {
    refetchOffersSent();
  }, []);

  useEffect(() => {
    if (state.offersSent) {
      setPendingPayments(state.offersSent.filter((post) => post.offers.find((offer) => offer.status === OfferStatus.ACCEPTED && !post.transaction)));
    }
  }, [state.offersSent]);

  return (
    <div>
      <ul style={styles.list}>
        {pendingPayments.length > 0 &&
          pendingPayments.map((post) => (
            <li style={styles.listItem}>
              <Link to={`/${post._id}`}>
                <p>{post.title}</p>
              </Link>
              <span style={styles.price}>
                ${post.offers.find((offer) => offer.status === OfferStatus.ACCEPTED)?.amount || "N/A"} (
                {btcToUsd && Number(post.offers.find((offer) => offer?.status === OfferStatus.ACCEPTED)?.amount) / btcToUsd} BTC)
              </span>
              <SmallButton variant="secondary" text="Pay Now" onClick={() => toggle({ type: ModalTypes.PAYMENT, value: post, isModalOpen: true })} />
            </li>
          ))}
      </ul>
    </div>
  );
}

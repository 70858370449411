import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  list: {
    listStyle: "none",
  },
  listItem: {
    backgroundColor: theme.colors.lightGreen,
    padding: 15,
    borderRadius: 7,
    color: theme.colors.secondary,
    margin: "7px 0 7px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  price: {
    fontWeight: "bold",
  },
};

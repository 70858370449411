import React, { ReactNode } from "react";
import { styles } from "./styles";

interface SectionProps {
  children: ReactNode;
  flex?: "row" | "column";
  title?: string;
}

function Section({ children, flex = "column", title = "" }: SectionProps) {
  return (
    <div
      style={{
        ...styles.container,
      }}
    >
      <h6 style={styles.header}>{title}</h6>
      <div
        style={{
          display: "flex",
          flexDirection: flex,
          justifyContent: "space-between",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default React.memo(Section);

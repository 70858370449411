import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction } from "react";
import { UserRegister } from "../../../types/user";
import { useStyles } from "../../styles";

interface CredentialsProps {
  setUser: Dispatch<SetStateAction<UserRegister>>;
  setShowCredentials: Dispatch<SetStateAction<boolean>>;
  showCredentials: boolean;
}

function Credentials({ setUser, setShowCredentials, showCredentials }: CredentialsProps): JSX.Element {
  const { styles } = useStyles({ showCredentials });

  return (
    <div style={styles.form}>
      <h3 style={styles.formTitle}>Register</h3>
      <TextField
        name="firstName"
        style={styles.fields}
        id="outlined-basic"
        label="First Name"
        variant="outlined"
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            firstName: e.target.value,
          }))
        }
      />
      <TextField
        name="lastName"
        style={styles.fields}
        id="outlined-basic"
        label="Last Name"
        variant="outlined"
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            lastName: e.target.value,
          }))
        }
      />
      <TextField
        name="username"
        style={styles.fields}
        id="outlined-basic"
        label="Username"
        variant="outlined"
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            username: e.target.value,
          }))
        }
      />

      <Button variant="contained" onClick={() => setShowCredentials(true)}>
        Next
      </Button>
    </div>
  );
}

export default React.memo(Credentials);

import React from "react";
import LoginForm from "./components/LoginForm";
import { useStyles } from "./styles";
import Logo from "../../../assets/images/logo-white.svg";

export default function Login() {
  const { styles, matchPhone } = useStyles();

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        {renderLogo(matchPhone)}
        <div style={styles.loginForm}>
          <LoginForm />
        </div>
      </div>
      <div style={styles.rightContainer}>
        <p style={styles.title}>
          <img src={Logo} alt="Logo" height={200} width={300} />
        </p>
      </div>
    </div>
  );
}

function renderLogo(matchPhone: boolean) {
  if (matchPhone) {
    return (
      <img
        src={Logo}
        style={{ marginLeft: "40%" }}
        alt="Logo"
        height={100}
        width={100}
      />
    );
  } else {
    return <></>;
  }
}

import { CSSProperties } from "@mui/styled-engine";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    height: 700,
    margin: "10px 0px",
    width: "20%",
    border: `1px solid ${theme.colors.primary}`,
    padding: 5,
    overflow: "hidden",
  },
  title: {
    padding: 15,
    fontSize: 22,
    fontWeight: 900,
    color: theme.colors.secondary,
  },
  input: {
    margin: "10px 0px",
  },
  price: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  params: {
    padding: "20px 7.5px",
  },
  button: {
    width: "100%",
    alignItems: "center",
  },
};

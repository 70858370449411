import { useMediaQuery } from "@mui/material";
import { isTablet } from "../../theme";

export function useStyles() {
  const matchTablet = useMediaQuery(isTablet);
  const styles = {
    container: {
      width: "97%", // matchTablet ? "97%" : "90%",
      margin: "auto",
    },
    pageContainer: {
      width: "100%",
    },
  };

  return {
    styles,
    matchTablet,
  };
}

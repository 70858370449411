import { theme } from "../../../../../theme";

export const styles = {
  container: {
    margin: "auto",
    marginTop: 20,
    padding: 25,
    width: "90%",
    border: `1px solid ${theme.colors.primary}`,
  },
  header: {},
};

import React from "react";
import PageCard from "../../components/PageCard";
import Block from "./components/Block";
import GenerateAddress from "./components/GenerateAddress";
import ResetPassword from "./components/ResetPassword";
import WithdrawFunds from "./components/WithdrawFunds";
import { styles } from "./styles";

export default function Settings() {
  return (
    <PageCard>
      <div style={styles.container}>
        <Block title="Withdraw Funds">
          <WithdrawFunds />
        </Block>

        <Block title="Generate New Address">
          <GenerateAddress />
        </Block>

        <Block title="Reset Password">
          <ResetPassword />
        </Block>
      </div>
    </PageCard>
  );
}

import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    width: "50%",
    margin: "auto",
  } as CSSProperties,
  price: {
    width: "100%",
    padding: 7,
    borderRadius: 7,
    backgroundColor: theme.colors.green,
    margin: 10,
  },
  meta: {
    fontWeight: "bold",
    marginRight: 5,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20,
  } as CSSProperties,
  fee: {
    color: theme.colors.red,
    fontWeight: 700,
    fontSize: 20,
  },
  cost: {
    fontSize: 20,
  },
};

import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  container: {
    width: "100%",
  } as CSSProperties,
  widget: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  } as CSSProperties,
  buttons: {
    marginRight: "10px",
  } as CSSProperties,
  pending: {
    backgroundColor: theme.colors.green,
    padding: "2px 6px",
    fontSize: 11,
  },
  alert: {
    marginBottom: "25px",
  },
};

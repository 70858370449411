import { AxiosError } from "axios";
import { useState } from "react";
import { cancelTransaction } from "../../../api/transactions";
import { TransactionPost } from "../../../types/transactions";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useCancelTransaction = () => {
  const [post, setPost] = useState<TransactionPost | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>();
  const { toggle } = useToggleToast();

  const doCancelTransaction = async (postId: string, callback?: Function) => {
    try {
      if (isLoading) return;

      setIsLoading(true);

      const _post = await cancelTransaction(postId);
      setPost(_post);

      callback && callback();
      toggle("Transaction Cancelled", ToastSeverity.SUCCESS);
    } catch (err) {
      setError(err as AxiosError);
      console.error(err);
      toggle((err as AxiosError<any>).response?.data.message, ToastSeverity.ERROR);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    post,
    error,
    doCancelTransaction,
  };
};

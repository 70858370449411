import Alert from "@mui/material/Alert";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PER_PAGE } from "../../../config";
import { usePostsContext } from "../../../context/posts";
import { OfferStatus } from "../../../types/offers";
import { PostStatus } from "../../../types/posts";
import PageCard from "../../components/PageCard";
import SmallButton from "../../components/SmallButton";
import { useUser } from "../../hooks/useUser";
import AwaitingPayment from "./components/AwaitingPayment";
import CryptoAddress from "./components/CryptoAddress";
import PendingTransactions from "./components/PendingTransactions";
import Widget from "./components/Widget";
import { styles } from "./styles";

export default function Dashboard() {
  const { user } = useUser();
  const { state, refetchPosts } = usePostsContext();
  const navigate = useNavigate();

  useEffect(() => {
    refetchPosts({
      user: user?.sub,
      page: 0,
      limit: PER_PAGE * 2,
    });
  }, [user]);

  const pendingOffers = useMemo(() => {
    let _pendingOffers = 0;
    state.posts.forEach((post) => {
      post.offers.forEach((offer) => {
        if (offer.status === OfferStatus.PENDING) _pendingOffers++;
      });
    });
    return _pendingOffers;
  }, [state.posts]);

  const sellerAwaitingBuyerPayment = useMemo(() => {
    let acceptedOffersAwaitingPayment = 0;
    state.posts.forEach((post) => {
      post.offers.forEach((offer) => {
        if (offer.status === OfferStatus.ACCEPTED && post.status === PostStatus.PENDING) {
          acceptedOffersAwaitingPayment++;
        }
      });
    });
    return acceptedOffersAwaitingPayment;
  }, [state.posts]);

  return (
    <PageCard>
      <div style={styles.container}>
        {sellerAwaitingBuyerPayment !== 0 ? (
          <div style={styles.alert}>
            <Alert variant="filled" severity="success">
              You have {sellerAwaitingBuyerPayment} post{sellerAwaitingBuyerPayment > 1 ? "s" : ""} awaiting payment
            </Alert>
          </div>
        ) : (
          <></>
        )}
        <span style={styles.buttons}>
          <SmallButton variant="secondary" text="Posts" onClick={() => navigate("/postings")} />
        </span>
        <span style={styles.buttons}>
          <SmallButton
            variant="secondary"
            text="Offers"
            onClick={() => navigate("/offers")}
            icon={pendingOffers > 0 ? <span style={styles.pending}>{pendingOffers}</span> : <></>}
          />
        </span>
        <span style={styles.buttons}>
          <SmallButton variant="secondary" text="Transactions" onClick={() => navigate("/transactions")} />
        </span>
        <div style={styles.widget}>
          <Widget title="Bitcoin Address">
            <CryptoAddress />
          </Widget>

          <Widget title="Awaiting Payments">
            <AwaitingPayment />
          </Widget>

          <Widget title="Pending Transactions">
            <PendingTransactions />
          </Widget>
        </div>
      </div>
    </PageCard>
  );
}

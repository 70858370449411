import { AddCircleOutlineOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { usePostsContext } from "../../../context/posts";
import { ModalTypes } from "../../../types/common";
import { TransactionStatus } from "../../../types/transactions";
import { User } from "../../../types/users";
import useToggleModal from "../../components/hooks/useToggleModal";
import TabTable from "../../components/TabTable";
import { TableColumn } from "../../components/types/table";
import { TransactionRow } from "../../Offers/types/table-models";
import { useUsersContext } from "../../../context/users";

const TAB_LABELS = ["Buyer", "Seller"];

export default function UserTransactions() {
  const { state, refetchUserTransactions } = usePostsContext();
  const {
    state: { user },
  } = useUsersContext();

  const { toggle } = useToggleModal();

  useEffect(() => {
    refetchUserTransactions();
  }, [state.transactions.length]);

  const buyerTransactions = state.transactions
    .filter((post) => (post.transaction.buyer as User)._id === user?.sub)
    .map((post) => ({
      ...post,
      status: post.transaction.status,
    }));
  const sellerTransactions = state.transactions
    .filter((post) => (post.transaction.seller as User)._id === user?.sub)
    .map((post) => ({
      ...post,
      status: post.transaction.status,
    }));

  const TABLE_HEADERS: TableColumn<TransactionRow>[] = [
    {
      label: "Post",
      render: ({ _id, title }) => <Link to={`/post/${_id}`}>{title}</Link>,
    },
    {
      label: "Created On",
      render: ({ createdAt }) => <>{new Date(createdAt).toDateString()}</>,
    },
    {
      label: "Listed Price",
      render: ({ price }) => <>${price.toFixed(2)}</>,
    },
    {
      label: "Sold For",
      render: ({ transaction }: TransactionRow) => <>${Number(transaction.amount).toFixed(2)}</>,
    },
    {
      label: "Status",
      render: ({ transaction }) => <>{transaction.status}</>,
    },
    {
      label: "",
      render: (transaction) => (
        <AddCircleOutlineOutlined
          style={{ cursor: "pointer" }}
          onClick={() =>
            toggle({
              type: ModalTypes.TRANSACTION,
              value: transaction,
              isModalOpen: true,
            })
          }
        />
      ),
    },
  ];

  return (
    // TODO: Fix these types
    <TabTable
      header="My Transactions"
      tabLabels={TAB_LABELS}
      isLoading={false} // TODO
      statusEntries={Object.values(TransactionStatus)}
      // @ts-ignore next-line
      columns={TABLE_HEADERS}
      tabOneEntries={buyerTransactions}
      tabTwoEntries={sellerTransactions}
    />
  );
}

import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  address: {
    padding: 7,
    backgroundColor: theme.colors.grey,
    textAlign: "center",
    boxShadow: theme.shadow.inverse,
    color: theme.colors.white,
  } as CSSProperties,
};

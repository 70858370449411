import * as React from "react";
import { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase, styles as localStyles, styles } from "./styles";
import { theme } from "../../../theme";
import { useAuthToken } from "../../hooks/useAuthToken";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/white-transparent.png";
import { useBalance } from "../../Payments/hooks/useBalance";
import DropDownMenu from "./components/DropDownMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import { useUsersContext } from "../../../context/users";

export default function Header() {
  const [query, setQuery] = useState<string>("");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { access_token } = useAuthToken();
  const { state } = useUsersContext();
  const navigate = useNavigate();
  const { balance, getBalance } = useBalance();
  const ref = useRef(null);

  const handleChange = (e: any) => {
    setQuery(e.target.value);
  };

  const onKeyUpEnter = (e: any) => {
    if (e.keyCode === 13) {
      if (query.length) navigate(`/search?q=${query}`);
    }
  };

  useEffect(() => {
    getBalance();
  }, [state.user]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <AppBar
      position="static"
      style={{
        background: theme.colors.primary,
      }}
    >
      <Toolbar>
        <Link to="/browse">
          <img src={Logo} alt="React Logo" height={45} width={70} />
        </Link>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} onChange={handleChange} onKeyUp={onKeyUpEnter} />
        </Search>
        <div style={localStyles.container}>
          {!access_token ? (
            <>
              <p style={localStyles.authText} onClick={() => navigate("/login")}>
                Sign In
              </p>
              <span style={localStyles.orText}>or</span>
              <p style={localStyles.authText} onClick={() => navigate("/register")}>
                Register
              </p>
            </>
          ) : (
            <div style={styles.signedIn}>
              <span style={styles.balance}>
                {balance.btc} BTC (${balance.usd.toFixed(2)} USD)
              </span>

              <p style={localStyles.email}>{state.user?.username}</p>
              <div style={localStyles.avatar}>
                <SettingsIcon
                  style={{ fontSize: 30, color: theme.colors.secondary, backgroundColor: theme.colors.white, borderRadius: 15 }}
                  onClick={() => setShowMenu((prev) => !prev)}
                />
                <div ref={ref} style={localStyles.dropdown}>
                  {showMenu && <DropDownMenu />}
                </div>
              </div>

              <Button
                style={{
                  ...localStyles.item,
                  backgroundColor: theme.colors.secondary,
                }}
                variant="contained"
                onClick={() => navigate("/create-post")}
              >
                {window.innerWidth < 1200 ? "+" : "NEW POST"}
              </Button>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

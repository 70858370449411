import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  container: {
    width: "100%",
    height: "200px",
    position: "absolute",
    left: 0,
    backgroundColor: theme.colors.secondary,
  } as CSSProperties,
  title: {
    fontFamily: "system-ui",
    color: theme.colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "4.5%",
  } as CSSProperties,
};

import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { RouteView } from "./navigation/RouteView";
import Provider from "./context/root";
import PostsProvider from "./context/posts";
import UsersProvider from "./context/users";

function App() {
  return (
    <BrowserRouter>
      <Provider>
        <UsersProvider>
          <PostsProvider>
            <RouteView />
          </PostsProvider>
        </UsersProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;

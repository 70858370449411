import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserRegister } from "../types/user";
import Credentials from "./components/Credentials";
import Information from "./components/Information";
import Logo from "../../../assets/images/logo-white.svg";
import Logo2 from "../../../assets/images/logo-secondary1.png";
import { useRegisterUser } from "../hooks/useRegisterUser";
import { useStyles } from "./styles";
import { ToastSeverity } from "../../components/types/toast-severity";
import { RegisterSuccess } from "../types/register";
import useToggleToast from "../../components/hooks/useToggleToast";
import validate from "validate.js";

const emailValidationConstraints = {
  email: {
    presence: true,
    email: true,
  },
};

export default function Register() {
  const [showCredentials, setShowCredentials] = useState<boolean>(false);
  const { styles, matchPhone } = useStyles({ showCredentials });
  const [user, setUser] = useState<UserRegister>({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    password2: "",
  });

  const navigate = useNavigate();

  const { doRegisterUser, isLoading, error } = useRegisterUser();

  const { toggle } = useToggleToast();

  const handleUserRegister = () => {
    const isValid = validate(user, emailValidationConstraints);
    if (false) {
      return toggle(isValid.email, ToastSeverity.ERROR);
    }

    doRegisterUser(user, handleRegisterSuccess);
  };

  const handleRegisterSuccess: RegisterSuccess = () => {
    toggle("Register Successful", ToastSeverity.SUCCESS);
    navigate("/login");
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        {renderLogo(matchPhone, styles)}
        {!showCredentials ? (
          <div style={styles.registerForm}>
            <Information setUser={setUser} setShowCredentials={setShowCredentials} showCredentials={showCredentials} />
          </div>
        ) : (
          <p style={styles.title}>
            <img src={Logo2} alt="Logo" height={125} width={200} />
          </p>
        )}
      </div>
      <div style={styles.rightContainer}>
        {renderLogo(matchPhone, styles)}
        {showCredentials ? (
          <div style={styles.registerForm}>
            <Credentials
              setUser={setUser}
              setShowCredentials={setShowCredentials}
              isLoading={isLoading}
              error={error}
              handleSubmit={handleUserRegister}
              showCredentials={showCredentials}
            />
          </div>
        ) : (
          <p style={styles.title}>
            <img src={Logo} alt="Logo" height={200} width={300} />
          </p>
        )}
      </div>
    </div>
  );
}
// @ts-ignore next-line
function renderLogo(isPhone: boolean, styles) {
  if (isPhone) {
    return (
      <p style={styles.title}>
        <img src={Logo} alt="Logo" height={100} width={100} />
      </p>
    );
  } else {
    return <></>;
  }
}

import { CSSProperties } from "react";

export const styles = {
  field: {
    width: "58%",
    marginTop: 25,
  },
  buttonContainer: {
    minWidth: "100%",
    textAlign: "center",
    marginBottom: 50,
  } as CSSProperties,
  button: {
    margin: "40px 10px 0 10px",
    width: "33%",
  },
  subfields: {
    width: "49%",
    marginTop: 25,
  },
  image: {
    padding: "10px 0px",
  },
};

import { AxiosError } from "axios";
import { useState } from "react";
import { acceptOffer } from "../../../api/offers";
import { useUsersContext } from "../../../context/users";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";

export const useAcceptOffer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const { state } = useUsersContext();
  const { toggle } = useToggleToast();

  const doAcceptOffer = async (postId: string, offerId: string, callback?: Function) => {
    try {
      if (isLoading) return;
      if (!state.user) return;

      setIsLoading(true);
      const x = await acceptOffer(postId, offerId);
      callback && callback();
      handleOfferSuccess();
    } catch (err) {
      setError(err as AxiosError);
      console.error(err);
      handleOfferFailure((err as AxiosError<any>).response?.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOfferSuccess = () => toggle("Offer Accepted", ToastSeverity.SUCCESS);
  const handleOfferFailure = (message: string) => toggle(message, ToastSeverity.ERROR);

  return {
    error,
    isLoading,
    doAcceptOffer,
  };
};

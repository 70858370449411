import React from "react";
import { useNavigate } from "react-router-dom";
import { createModuleStyleExtractor } from "../../../utils/style-extractor";
import { useFetchLocations } from "../hooks/useFetchLocations";
import styles from "./style.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Logo from "../../../assets/images/white-transparent.png";

const cx = createModuleStyleExtractor(styles);

const CitySelect = () => {
  const { locations, isLoading } = useFetchLocations();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
  };

  return (
    <div className={cx("o-location-container")}>
      <div className={cx("m-search-city")}>
        <div className={cx("m-search-city__container")}>
          <div className={cx("m-search-city__container__inside__heading")}>
            <img src={Logo} alt="React Logo" height={40} width={65} />
          </div>

          <div className={cx("m-search-city__container__inside__sub-heading")}>
            <p>Where are you located?</p>
            <hr className={cx("divider")} />
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              locations &&
              locations.map((item, index) => (
                <li key={`alpha_content_${index}`} onClick={handleOnClick}>
                  <span>
                    {item.city.trim()}, {item.country.trim()}
                  </span>
                </li>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitySelect;

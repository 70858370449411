import React from "react";
import { useUsersContext } from "../../../../../context/users";
import { styles } from "./styles";

export default function CryptoAddress() {
  const {
    state: { user },
  } = useUsersContext();
  return <div style={styles.address}>{user?.wallet}</div>;
}

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { styles } from "./style";
import { Post } from "../../../../../../types/posts";
import { Link } from "react-router-dom";
import { User } from "../../../../../../types/users";

interface PostCardProps {
  post: Post;
}

function PostCard({ post }: PostCardProps) {
  return (
    <Link style={styles.card} to={`/post/${post._id}`}>
      <Card>
        <CardHeader
          avatar={<Avatar style={styles.avatar}>{(post && post.author && (post.author as User).firstName.slice(0, 1).toUpperCase()) || "A"}</Avatar>}
          title={post.title}
          subheader={post.createdAt}
        />
        <CardMedia style={styles.image} component="img" image={post.images[0]} alt="" />
        <CardContent>
          <div style={styles.cardInfo}>
            <p>
              <b>Price:</b> ${post.price}
            </p>
            <p>
              <b>Condition:</b> {post.condition}/10
            </p>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
}

export default React.memo(PostCard);

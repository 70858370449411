export const theme = {
  colors: {
    primary: "#f4a261",
    secondary: "#264653",
    compliment: "#1976d2",
    white: "#f8f9f9",
    grey: "#bdbdbd",
    green: "#7fff00",
    lightGreen: "#DAF7A6",
    charcoal: "#0D0907",
    red: "#E3242B",
  },
  shadow: {
    form: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    card: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    inverse: "inset 0 0 5px",
  },
  styles: {
    errorText: {
      color: "#E3242B",
      marginTop: 10,
    },
  },
};

export const isPhone = "(max-width:395px)";
export const isTablet = "(max-width:1025px)";

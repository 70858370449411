import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  button: {
    width: "12%",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  address: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  input: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: 10,
    marginTop: 0,
  } as CSSProperties,
  icon: {
    color: theme.colors.green,
    fontSize: 56,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  confirmButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  success: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  iconText: {
    color: theme.colors.green,
  },
};

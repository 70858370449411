import React, { ReactNode } from "react";
import { useStyles } from "./styles";

interface PageCardProps {
  children: ReactNode;
}

function PageCard({ children }: PageCardProps) {
  const { styles } = useStyles();
  return <div style={styles.container}>{children}</div>;
}

export default React.memo(PageCard);

import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { theme } from "../../../theme";
import { CSSProperties } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  marginLeft: 0,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: "black",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    color: "black",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const styles = {
  authRouteMessages: {
    width: "80%",
    marginLeft: 20,
    display: "flex",
    justifyContent: "right",
  },
  orText: {
    marginLeft: 10,
    marginRight: 10,
  },
  authText: {
    cursor: "pointer",
    color: theme.colors.secondary,
  },
  settings: {
    width: "30%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  email: {
    marginTop: "0.8em",
    marginRight: 10,
    color: theme.colors.secondary,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
  } as CSSProperties,
  item: {
    marginLeft: 30,
  },
  avatar: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  dropdown: {
    backgroundColor: "#ffffff",
    color: theme.colors.secondary,
    position: "absolute",
    zIndex: 1,
    borderRadius: 5,
    marginTop: 165,
    boxShadow: theme.shadow.card,
  } as CSSProperties,
  dropdownIcon: {
    marginRight: 10,
  },
  balance: {
    fontSize: 14,
    marginRight: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  signedIn: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    alignContent: "center",
  } as CSSProperties,
};

export { SearchIconWrapper, Search, StyledInputBase, styles };

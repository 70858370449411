import React, { useEffect, useState } from "react";
import { usePostsContext } from "../../../../../context/posts";
import { ModalTypes } from "../../../../../types/common";
import { TransactionPost, TransactionStatus } from "../../../../../types/transactions";
import useToggleModal from "../../../../components/hooks/useToggleModal";
import { styles } from "./styles";

export default function PendingTransactions() {
  const [pendingTransactions, setPendingTransactions] = useState<TransactionPost[]>([]);
  const { state, refetchUserTransactions } = usePostsContext();
  const { toggle } = useToggleModal();

  useEffect(() => {
    refetchUserTransactions();
  }, []);

  useEffect(() => {
    if (state.transactions) {
      const pendingTransactionPosts = state.transactions.filter((post) => post.transaction.status === TransactionStatus.PENDING);
      setPendingTransactions(pendingTransactionPosts);
    }
  }, [state.transactions]);

  return (
    <ul>
      {pendingTransactions.map((transaction) => (
        <li
          style={{
            ...styles.listItem,
          }}
        >
          <div onClick={() => toggle({ type: ModalTypes.TRANSACTION, value: transaction, isModalOpen: true })}>
            <p>{transaction.title}</p>
          </div>
          <span style={styles.price}>${transaction.transaction.amount}</span>
        </li>
      ))}
    </ul>
  );
}

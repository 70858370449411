import { CheckCircleOutline } from "@mui/icons-material";
import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useState } from "react";
import { useResetPassword } from "../../../hooks/useResetPassword";
import { styles } from "./styles";

export default function ResetPassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [_newPassword, _setNewPassword] = useState("");
  const { doResetUser, isLoading, result } = useResetPassword();

  return (
    <div style={styles.container}>
      {result ? (
        <div style={styles.success}>
          <div style={styles.iconContainer}>
            <CheckCircleOutline style={styles.icon} />
          </div>
          <p style={styles.iconText}>Password Reset</p>
        </div>
      ) : (
        <div>
          <div style={styles.input}>
            <TextField label="Old Password" size="small" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
          </div>
          <div style={styles.input}>
            <TextField
              style={{ marginRight: 5 }}
              label="New Password"
              size="small"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              style={{ marginLeft: 5 }}
              label="Repeat New Password"
              size="small"
              value={_newPassword}
              onChange={(e) => _setNewPassword(e.target.value)}
            />
          </div>
          {isLoading ? (
            <div style={styles.button}>
              <CircularProgress />
            </div>
          ) : (
            <Button style={styles.button} variant="contained" onClick={() => doResetUser(oldPassword, newPassword)}>
              Reset
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

import { Offer } from "./offers";
import { Payment } from "./payment";
import { Post } from "./posts";
import { User } from "./users";

/**
 * Post with transaction
 */
export type TransactionPost = Post & {
  transaction: Transaction;
  payment: Payment;
  offer: Offer;
};

export type Transaction = {
  buyer: string | User;
  seller: string | User;
  status: TransactionStatus;
  amount: number;
  _id: string;
  createdAt: string;
  updatedAt: string;
};

export enum TransactionStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  DISPUTED = "DISPUTED",
}

import { isPhone, theme } from "../../../theme";
import { CSSProperties } from "react";
import { useMediaQuery } from "@mui/material";

export function useStyles() {
  const matchPhone = useMediaQuery(isPhone);
  const styles = {
    container: {
      textAlign: "center",
      minHeight: "100vh",
      display: "flex",
    } as CSSProperties,
    leftContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: matchPhone ? "100%" : "50%",
      backgroundColor: matchPhone ? theme.colors.primary : theme.colors.white,
    } as CSSProperties,
    rightContainer: {
      display: matchPhone ? "none" : "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "50%",
      backgroundColor: theme.colors.primary,
    } as CSSProperties,
    loginForm: {
      minHeight: 300,
      width: matchPhone ? "100%" : "50%",
      backgroundColor: theme.colors.secondary,
      margin: "auto",
      boxShadow: theme.shadow.form,
      padding: 0,
    },
    title: {
      fontWeight: 700,
      fontSize: 42,
      color: "white",
    },
  };

  return {
    styles,
    matchPhone,
  };
}

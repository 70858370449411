import { OfferRow } from "../modules/Offers/types/table-models";
import { Post } from "../types/posts";

export function capitalizeFirstLetter(str: string) {
  const words = str.split(" ");
  return words.map((word) => word.slice(0, 1).toUpperCase() + word.slice(1, str.length).toLowerCase()).join(" ");
}

export function mapPostToTableRow(rows: Post[]): OfferRow[] {
  let offers = [];

  for (let row of rows) {
    for (let offer of row.offers) {
      offers.push({
        ...row,
        offer,
        status: offer.status,
      });
    }
  }

  return offers;
}

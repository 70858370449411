import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { usePostsContext } from "../../../../../context/posts";
import { TransactionPost } from "../../../../../types/transactions";
import { useCancelTransaction } from "../../../../Transactions/hooks/useCancelTransaction";
import { useCompleteTransaction } from "../../../../Transactions/hooks/useCompleteTransaction";
import useToggleModal from "../../../hooks/useToggleModal";
import Header from "../Header";
import { styles } from "./styles";
import { useUsersContext } from "../../../../../context/users";

interface TransactionProps {
  post: TransactionPost;
}

export default function Transaction({ post }: TransactionProps) {
  const { doCompleteTransaction, isLoading: isLoadingComplete } = useCompleteTransaction();
  const { doCancelTransaction, isLoading: isLoadingCancel } = useCancelTransaction();
  const { refetchUserTransactions } = usePostsContext();
  const { toggle } = useToggleModal();
  const { state } = useUsersContext();

  const closeModal = () => {
    refetchUserTransactions();
    toggle(null);
  };
  const isSeller = state.user?.sub === post.author;

  return (
    <div>
      <Header post={post} model="Transaction" status={post.transaction.status} />
      <div style={styles.status}>
        <div>
          <b>Transaction Status: </b>
          {post.transaction.status}
        </div>
      </div>
      <h3 style={{ ...styles.amount, ...styles.price }}>
        <span style={styles.meta}>Offer:</span> ${post.transaction.amount}
      </h3>

      {isLoadingCancel || isLoadingComplete ? (
        <CircularProgress />
      ) : (
        <div style={styles.button}>
          {isSeller ? (
            <Button onClick={() => doCancelTransaction(post._id, closeModal)} variant="contained">
              Cancel Transaction
            </Button>
          ) : (
            <Button onClick={() => doCompleteTransaction(post._id, closeModal)} variant="contained">
              Handshake
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

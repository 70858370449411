import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction } from "react";
import { theme } from "../../../../../theme";
import { UserRegister } from "../../../types/user";
import { useStyles } from "../../styles";

interface InformationProps {
  setUser: Dispatch<SetStateAction<UserRegister>>;
  setShowCredentials: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
  error: Error | null;
  isLoading: boolean;
  showCredentials: boolean;
}

function Information({ setShowCredentials, setUser, handleSubmit, error, isLoading, showCredentials }: InformationProps): JSX.Element {
  const { styles } = useStyles({ showCredentials });
  return (
    <div style={styles.form}>
      <h3 style={styles.formTitle}>User Credentials</h3>
      <TextField
        name="email"
        style={styles.fields}
        id="outlined-basic"
        label="Email"
        variant="outlined"
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            email: e.target.value,
          }))
        }
      />
      <TextField
        type="password"
        name="password"
        style={styles.fields}
        id="outlined-basic"
        label="Password"
        variant="outlined"
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            password: e.target.value,
          }))
        }
      />
      <TextField
        type="password"
        name="password2"
        style={styles.fields}
        id="outlined-basic"
        label="Re-enter Password"
        variant="outlined"
        onChange={(e) =>
          setUser((prev) => ({
            ...prev,
            password2: e.target.value,
          }))
        }
      />
      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              style={{
                ...styles.button,
                backgroundColor: theme.colors.white,
              }}
              onClick={() => setShowCredentials(false)}
              variant="outlined"
            >
              Back
            </Button>
            <Button style={styles.button} onClick={() => handleSubmit()} variant="contained">
              Register
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(Information);

import { CSSProperties } from "react";
import { theme } from "../../../theme";

export const styles = {
  container: {
    padding: 25,
    overflow: "hidden",
  } as CSSProperties,
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: theme.colors.secondary,
  } as CSSProperties,
  link: {
    textDecoration: "none",
    hover: {
      textDecoration: "underline",
    },
  } as CSSProperties,
};

import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  balance: {
    width: "50%",
    margin: "auto",
    padding: 7,
    backgroundColor: theme.colors.grey,
    textAlign: "center",
    boxShadow: theme.shadow.inverse,
    color: theme.colors.white,
  } as CSSProperties,
  address: {
    paddingTop: 10,
    margin: "auto",
  },
  addressPreview: {
    padding: 2,
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
  },
  button: {
    width: "12%",
    margin: "auto",
    paddingTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  icon: {
    color: theme.colors.green,
    fontSize: 56,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  confirmButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as CSSProperties,
  text: {
    padding: 10,
  },
  warn: {
    color: theme.colors.red,
    fontWeight: 700,
  },
  success: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  iconText: {
    color: theme.colors.green,
  },
};

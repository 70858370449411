import axios from "../utils/axios";
import { PaginationResult } from "../types/pagination";
import { Post, PostQueryParams } from "../types/posts";

/**
 * Search Posts
 * @param query
 * @returns
 */
export const searchPosts = async (query: string) => {
  const response = await axios.get<{ items: Post[] }>(query ? `search?q=${query}` : `/search`);
  return response.data;
};

/**
 * Fetch Posts
 * @param userId
 * @returns
 */
export const fetchPosts = async ({ user, page, limit, category, query, status }: PostQueryParams) => {
  let url = `posts?page=${page}&limit=${limit}`;
  if (category) url += `&category=${category}`;
  if (user) url += `&user=${user}`;
  if (query) url += `&query=${query}`;
  if (status) url += `&status=${status}`;

  const response = await axios.get<PaginationResult<Post>>(url);
  return response.data;
};

export const fetchPost = async (postId: string) => {
  const response = await axios.get<Post>(`posts/${postId}/`);
  return response.data;
};

export const createPost = async (data: FormData) => {
  const response = await axios.put<Post>(`auth/posts/create`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};
export const editPost = async (data: Post) => {
  const response = await axios.patch<{ acknowledged: boolean }>(`auth/posts/${data._id}/edit`, data);
  return response.data;
};

export const deletePost = async (postId: string) => {
  const response = await axios.delete<{ acknowledged: boolean }>(`auth/posts/${postId}/delete`);
  return response.data;
};

import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    height: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  } as CSSProperties,
  title: {
    color: theme.colors.white,
  },
  fields: {
    width: "85%",
    margin: "15px 0px 15px 0px",
    background: theme.colors.white,
  },
};

import { RootAction } from "../types/action";
import { RootState } from "../types/state";
import {
  ActionTypes,
  ToggleModal,
  ToggleSidesheet,
  ToggleToast,
} from "./actions";

export function rootReducer(state: RootState, action: RootAction): RootState {
  switch (action.type) {
    case ActionTypes.TOGGLE_SIDESHEET:
      return {
        ...state,
        isSidesheetOpen: (action.payload as ToggleSidesheet).isSidesheetOpen,
      };
    case ActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        model: (action.payload as ToggleModal).model,
      };
    case ActionTypes.TOGGLE_TOAST:
      return {
        ...state,
        toast: (action.payload as ToggleToast).toast,
      };
    default:
      return {
        ...state,
      };
  }
}

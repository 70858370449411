import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PER_PAGE } from "../../../config";
import { usePostsContext } from "../../../context/posts";
import { PostQueryParams, PostStatus } from "../../../types/posts";
import PostsList from "../components/PostsList";
import Sidebar from "./components/Sidebar";
import { styles } from "./styles";

export default function Browse() {
  const [page, setPage] = useState(0);
  const location = useLocation();
  const { refetchPosts, state, isLoading, numOfPages } = usePostsContext();

  useEffect(() => {
    const params: PostQueryParams = {
      page,
      limit: PER_PAGE,
      status: PostStatus.OPEN,
    };

    const urlp = new URLSearchParams(location.search);
    // @ts-ignore
    for (const [key, value] of urlp.entries()) {
      // @ts-ignore
      params[key] = value;
    }

    refetchPosts(params);
  }, [location]);

  return (
    <div style={styles.container}>
      <Sidebar />
      <div style={styles.postContainer}>
        <PostsList posts={state.posts} isLoading={isLoading} />
        <Pagination count={numOfPages} page={page + 1} onChange={(e, p) => setPage(p - 1)} />
      </div>
    </div>
  );
}

import { useState } from "react";
import { declineOffer } from "../../../api/offers";
import { useUsersContext } from "../../../context/users";

export const useDeclineOffer = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { state } = useUsersContext();

  const doDeclineOffer = async (postId: string, offerId: string, callback: Function) => {
    try {
      if (isLoading) return;
      if (!state.user?.sub) return;

      setIsLoading(true);
      await declineOffer(postId, offerId);

      callback && callback();
    } catch (err) {
      setError(err as Error);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    error,
    isLoading,
    doDeclineOffer,
  };
};

import { useState } from "react";
import CategoryBar from "../../Categories/CategoryBar";
import Banner from "../../Common/Banner";
import PaginatedPosts from "../components/PaginatedPosts";
import { styles } from "./style";

const NUM_POSTS = 4;

const Feature = () => {
  const [filter] = useState({
    page: 0,
    limit: NUM_POSTS, // Feature page should just show 4 posts
  });

  return (
    <>
      <CategoryBar />
      <Banner title="Browse Items For Sale, List Your Items, Earn Crypto." />
      <div style={styles.posts}>
        <PaginatedPosts filter={filter} />
      </div>
    </>
  );
};
export default Feature;

import { Button, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { useCategories } from "../../../../Categories/hooks/useCategories";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../../../components/types/toast-severity";
import { styles } from "./styles";

export default function Sidebar() {
  const { toggle } = useToggleToast();
  const { categories } = useCategories();
  const [category, setCategory] = useState<string>("");
  const [keyword, setKeyword] = useState<string>();
  const [min, setMin] = useState<string>();
  const [max, setMax] = useState<string>();

  const onSubmit = () => {
    if (min || (max && (!Number(min) || !Number(max)))) toggle("Min and Max values must be a number", ToastSeverity.ERROR);
    const urlp = new URLSearchParams();
    if (category) urlp.set("category", category);
    if (keyword) urlp.set("query", keyword);
    if (min) urlp.set("min", min);
    if (max) urlp.set("max", max);

    window.location.replace("browse?" + urlp.toString());
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>for sale</div>
      <div style={styles.params}>
        <TextField
          style={styles.input}
          onChange={(e) => setKeyword(e.target.value)}
          id="standard-size-small"
          label="keyword"
          size="small"
          variant="standard"
        />
        {/** @ts-ignore */}
        <div style={styles.price}>
          <TextField style={styles.input} onChange={(e) => setMin(e.target.value)} label="min" id="standard-size-small" size="small" />
          <TextField style={styles.input} onChange={(e) => setMax(e.target.value)} label="max" id="standard-size-small" size="small" />
        </div>
        <div style={styles.input}>
          <Select size="small" label="Condition" value={category} onChange={(e) => setCategory(e.target.value)}>
            {categories.map((category) => (
              <MenuItem key={category._id} value={category.title.toLowerCase()}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button style={styles.button} variant="contained" onClick={() => onSubmit()}>
          Find
        </Button>
      </div>
    </div>
  );
}

import { TransactionPost } from "../types/transactions";
import axios from "../utils/axios";
/**
 * Fetch transactions
 * @param query
 * @returns
 */
export const getTransactions = async () => {
  const response = await axios.get<TransactionPost[]>(`auth/transactions`);
  return response.data;
};

export const completeTransaction = async (postId: string) => {
  const response = await axios.post<TransactionPost>(`auth/transactions/post/${postId}/complete`);
  return response.data;
};

export const cancelTransaction = async (postId: string) => {
  const response = await axios.post<TransactionPost>(`auth/transactions/post/${postId}/cancel`);
  return response.data;
};

import { CSSProperties } from "react";

export const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  postContainer: {
    width: "78%",
  },
};

import React, { useContext, useReducer } from "react";
import { RootAction } from "../types/action";
import { RootState } from "../types/state";
import { rootReducer } from "./reducer";
import { initialRootState } from "./state";

interface RootContextProps {
  state: RootState;
  dispatch: React.Dispatch<RootAction>;
}

interface RootContextProviderProps {
  children: React.ReactNode;
}

export const RootContext = React.createContext<RootContextProps>({
  state: initialRootState,
  dispatch: () => {},
});

export default function RootContextProvider({
  children,
}: RootContextProviderProps) {
  const [state, dispatch] = useReducer(rootReducer, initialRootState);

  return (
    <RootContext.Provider value={{ state, dispatch }}>
      {children}
    </RootContext.Provider>
  );
}

export function useRootContext() {
  const { dispatch, state } = useContext(RootContext);

  return {
    dispatch,
    state,
  };
}

import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreatePost } from "../../../types/posts";
import { useCategories } from "../../Categories/hooks/useCategories";
import useToggleToast from "../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../components/types/toast-severity";
import { useCreatePost } from "../hooks/usePostCreate";
import Section from "./components/Section";
import { styles } from "./style";

const INITIAL_POST = {
  title: "Playstation 5 for sale - $600 OBO",
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
  condition: 7,
  price: 0.11,
  category: [],
  images: null,
  address: {
    street: "Fake St.",
    state: "British Columbia",
    city: "Vancouver",
    country: "Canada",
    postalCode: "V1V1V1",
  },
};

const CONDITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const IMAGE_LIMIT = 3;
const BETA_COUNTRY = "Canada";

const PROVINCES = [
  {
    name: "Alberta",
    abbreviation: "AB",
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
  },
  {
    name: "New Brunswick",
    abbreviation: "NB",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
  },
  {
    name: "Quebec",
    abbreviation: "QC",
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK",
  },
  {
    name: "Yukon Territory",
    abbreviation: "YT",
  },
];

export default function PostCreate() {
  const [post, setPost] = useState<CreatePost>(INITIAL_POST);
  // @ts-ignore
  const [images, setImages] = useState<FileList>([]);

  const { isLoading, doCreatePost } = useCreatePost();
  const { categories } = useCategories();

  const navigate = useNavigate();
  const { toggle } = useToggleToast();

  const navigateOnSubmit = () => navigate("/browse");

  const handleSetImages = (i: FileList) => {
    if (!i) return;
    if (i.length > IMAGE_LIMIT) toggle(`You may only upload a maximum of ${IMAGE_LIMIT} images`, ToastSeverity.WARNING);

    setImages(i);
  };

  const handleOnSubmit = () => {
    if (images.length > IMAGE_LIMIT) return toggle(`You cannot select more than ${IMAGE_LIMIT} images`, ToastSeverity.ERROR);
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append("files", images[i]);
    }

    formData.append("title", String(post.title));
    formData.append("description", String(post.description));
    formData.append("condition", String(post.condition));
    formData.append("price", String(post.price));
    formData.append("category", String(post.category));
    formData.append("street", post.address.street);
    formData.append("state", post.address.state);
    formData.append("city", post.address.city);
    formData.append("postalCode", post.address.postalCode);
    formData.append("country", BETA_COUNTRY);

    doCreatePost(formData, navigateOnSubmit);
  };

  return (
    <>
      <Section title="Enter the title and description of your post.">
        <TextField
          size="small"
          style={styles.field}
          id="title"
          label="Title"
          variant="outlined"
          value={post.title}
          onChange={(e) =>
            setPost({
              ...post,
              title: e.target.value,
            })
          }
        />
        <TextField
          size="small"
          style={styles.field}
          id="description"
          label="Description"
          variant="outlined"
          multiline
          rows={2}
          value={post.description}
          onChange={(e) =>
            setPost({
              ...post,
              description: e.target.value,
            })
          }
        />
      </Section>

      <Section title="Enter the condition of the item, 10 = Perfect Condition." flex="row">
        <FormControl style={styles.subfields}>
          <InputLabel>Condition</InputLabel>
          <Select size="small" label="Condition" value={post.condition} onChange={(e) => setPost({ ...post, condition: Number(e.target.value) })}>
            {CONDITIONS.map((val) => (
              <MenuItem value={val}>{val}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          size="small"
          style={styles.subfields}
          id="price"
          label="Price"
          variant="outlined"
          value={post.price}
          onChange={(e) =>
            setPost({
              ...post,
              price: parseInt(e.target.value),
            })
          }
        />
      </Section>

      <Section title="Which category does your item/service belong?" flex="row">
        <FormControl style={styles.field} fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            size="small"
            id="category"
            label="Category"
            value={post.category}
            onChange={(e) =>
              setPost({
                ...post,
                category: [e.target.value] as string[],
              })
            }
          >
            {categories.map((category) => (
              <MenuItem value={category.title.toLowerCase()}>{category.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Section>

      <Section title="Upload up to 3 images (jpeg, jpg, png).">
        <input
          name="files"
          type="file"
          style={styles.image}
          multiple
          accept="image/png, image/jpeg, image/jpg"
          // @ts-ignore
          onChange={(e) => handleSetImages(e.target.files)}
          disabled={false}
        />
      </Section>

      <Section title="Where is the item/service located?">
        {/* <TextField
          size="small"
          style={styles.field}
          id="street"
          label="Street"
          variant="outlined"
          value={post.address.street}
          onChange={(e) =>
            setPost({
              ...post,
              address: {
                ...post.address,
                street: e.target.value,
              },
            })
          }
        /> */}
        <TextField
          size="small"
          style={styles.field}
          id="city"
          label="City"
          variant="outlined"
          value={post.address.city}
          onChange={(e) =>
            setPost({
              ...post,
              address: {
                ...post.address,
                city: e.target.value,
              },
            })
          }
        />
        <FormControl style={styles.field}>
          <InputLabel>Province/State</InputLabel>
          <Select
            size="small"
            value={post.address.state}
            onChange={(e) =>
              setPost({
                ...post,
                address: {
                  ...post.address,
                  state: e.target.value,
                },
              })
            }
          >
            {PROVINCES.map((val) => (
              <MenuItem value={val.name}>{val.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          size="small"
          style={styles.field}
          id="postalCode"
          label="Postal Code"
          variant="outlined"
          value={post.address.postalCode}
          onChange={(e) =>
            setPost({
              ...post,
              address: {
                ...post.address,
                postalCode: e.target.value,
              },
            })
          }
        />
        <TextField size="small" style={styles.field} id="country" label="Country" variant="outlined" value={BETA_COUNTRY} disabled={true} />
      </Section>

      <div style={styles.buttonContainer}>
        {!isLoading ? (
          <Button size="small" onClick={() => handleOnSubmit()} variant="contained" style={styles.button}>
            Create Post
          </Button>
        ) : (
          <CircularProgress />
        )}
      </div>
    </>
  );
}

import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginParams } from "../../../../../types/users";
import useToggleToast from "../../../../components/hooks/useToggleToast";
import { ToastSeverity } from "../../../../components/types/toast-severity";
import { useLoginUser } from "../../../hooks/useLoginUser";
import { styles } from "./styles";

export default function LoginForm() {
  const [user, setUser] = useState<LoginParams>({
    email: "",
    password: "",
  });
  const { doLoginUser, isLoading } = useLoginUser();
  const { toggle } = useToggleToast();

  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    toggle("Login Successful", ToastSeverity.SUCCESS);
    navigate("/browse");
  };

  const handleLoginError = () => toggle("Error Logging In", ToastSeverity.ERROR);

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Sign In</h3>
      <TextField
        name="email"
        style={styles.fields}
        id="outlined-basic"
        label="Email"
        variant="outlined"
        onChange={(e) =>
          setUser({
            ...user,
            email: e.target.value,
          })
        }
      />
      <TextField
        type="password"
        name="password"
        style={styles.fields}
        id="outlined-basic"
        label="Password"
        variant="outlined"
        onChange={(e) =>
          setUser({
            ...user,
            password: e.target.value,
          })
        }
      />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Button onClick={() => doLoginUser(user, handleLoginSuccess, handleLoginError)} variant="contained">
          Sign In
        </Button>
      )}
    </div>
  );
}

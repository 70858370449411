import { CSSProperties } from "react";
import { theme } from "../../../../../theme";

export const styles = {
  container: {
    width: "47%",
    border: `1px solid ${theme.colors.primary}`,
    boxShadow: theme.shadow.card,
    borderRadius: 3,
    padding: 10,
    marginBottom: 40,
  },
  header: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 20,
    textAlign: "center",
    color: theme.colors.secondary,
  } as CSSProperties,
};

import React from "react";
import { styles } from "./style";

interface BannerProps {
  title: string;
}

function Banner({ title }: BannerProps) {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>{title}</h1>
    </div>
  );
}

export default React.memo(Banner);

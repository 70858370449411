import { Button, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { usePostsContext } from "../../../../../context/posts";
import { OfferStatus } from "../../../../../types/offers";
import { Post } from "../../../../../types/posts";
import { useBalance } from "../../../../Payments/hooks/useBalance";
import { useConversionRate } from "../../../../Payments/hooks/useConversionRate";
import { usePayment } from "../../../../Payments/hooks/usePayment";
import useToggleModal from "../../../hooks/useToggleModal";
import SectionHeader from "../../../SectionHeader";
import { styles } from "./styles";

/**
 * TODO: get this from the backend
 */
const TRANSACTION_FEE = 5;

interface PaymentProps {
  post: Post;
}

function Payment({ post }: PaymentProps) {
  const { btcToUsd } = useConversionRate();
  const { balance, getBalance } = useBalance();
  const { doMakePayment, isLoading } = usePayment();
  const { refetchOffersSent, refetchUserTransactions } = usePostsContext();

  const { toggle } = useToggleModal();

  useEffect(() => {
    getBalance();
  }, []);

  const refetch = async () => {
    refetchOffersSent();
    refetchUserTransactions();
  };

  const handleOnClick = async () => {
    doMakePayment(post._id, refetch);
    toggle(null);
  };

  const acceptedOffer = post.offers.find((offer) => offer.status === OfferStatus.ACCEPTED);

  return (
    <div>
      <SectionHeader text="Make Payment" />
      <div style={styles.container}>
        <div>{post.title}</div>
        <div style={styles.price}>
          ${acceptedOffer?.amount} ({btcToUsd && (acceptedOffer?.amount || 0) / btcToUsd} BTC)
        </div>
        <div>
          <span style={styles.fee}>Fee: </span>
          <span style={styles.cost}>${TRANSACTION_FEE}</span>
        </div>
        <div>
          <span style={styles.meta}>Your Balance </span>
          <span>{balance.btc + " "} BTC</span>
        </div>
        <div>
          <span style={styles.meta}>Total </span>
          <span>${(acceptedOffer?.amount || 0) + TRANSACTION_FEE} USD</span>
        </div>
        <div style={styles.button}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button onClick={() => handleOnClick()} variant="contained">
              Pay
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(Payment);

import { CSSProperties } from "react";
import { theme } from "../../../../../../theme";

export const styles = {
  container: {
    marginTop: 5,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    border: `1px solid ${theme.colors.primary}`,
    boxShadow: theme.shadow.form,
  } as CSSProperties,
  picture: {
    height: 65,
    width: 95,
  },
  content: {
    width: "calc(100% - 110px)",
  },
  meta: {
    width: 100,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as CSSProperties,
  date: {
    marginTop: 5,
    fontSize: 11,
    color: theme.colors.primary,
  },
};

import React from "react";
import { createModuleStyleExtractor } from "../../../utils/style-extractor";
import styles from "./style.module.scss";

const cx = createModuleStyleExtractor(styles);

const Footer = () => {
  return (
    <div className={cx("m-footer")}>
      <div className={cx("m-footer__container")}>
        <ul key={`alpha_content_1`}>
          <li>About </li>
          <li>Contact </li>
          <li>Agreement</li>
        </ul>
      </div>
      <hr className={cx("divider")} />
      <p>© 2022 Gryp. All rights reserved.</p>
    </div>
  );
};

export default Footer;

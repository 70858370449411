import React from "react";
import { Outlet } from "react-router-dom";
import { useStyles } from "../styles";

export default function Page() {
  const { styles } = useStyles();
  return (
    <div style={styles.pageContainer}>
      <Outlet />
    </div>
  );
}
